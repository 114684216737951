<template>
	<div class="box">
		<div class="cont">
			首页
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		created() {			
			// this.getuserinfo()
		},
		methods: {
			getuserinfo(){
				this.$post({
					url:'/api/user/info'
				}).then((res)=>{
					localStorage.setItem('userinfo', JSON.stringify(res));//本地缓存					
					this.$store.commit('setUserInfo', res)
				})
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.cont{
		width: 100%;
		height: 100%;
		background-color: #fff;
		font-size: 22px;
		padding: 0.2rem;
	}
</style>